
  /* input:focus {
    background-color: #ff9121;
    background-color: #8af28f;
  } */

  .selectStyle > div:focus {
    background-color: #ff9121; 
    /* background-color: #b8d8f3;  */
  }
 
  .MuiAutocomplete-input {
    text-transform: 'uppercase';
  }

  /* .MultiLoanDelivery:hover, .MultiLoanDelivery:active, .MultiLoanDelivery:focus{
    border-top: 3px solid #00ab55;
    color: #00ab55;
    border-radius: 0px !important;
    background-color: #ebf8f2;
  } */

  .MultiLoanDelivery.active{
    border-top: 3px solid #ff9121;
    color: #ff9121;
    /* border-top: 3px solid #00ab55;
    color: #00ab55; */
    border-radius: 0px !important;
    background-color: #ebf8f2;
  }
  /* .active, .selected{
    border-top: 3px solid #00ab55;
    color: #00ab55;
    border-radius: 0px !important;
    background-color: red;
  } */

  hr.hrstyle-two{
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  }

  
  .highlght{
    color:#00ab55;
  }
  
  .MuiDataGrid-columnHeader{
    position: sticky !important;
  }